import type { Image } from 'client/components/image/image.types';
import type { BackgroundVideo } from 'client/components/background-video/background-video.types';

export enum MediaType {
  Image = 'image',
  Video = 'video',
}

export type ImageMedia = {
  props: Image;
  type: MediaType.Image;
};

export type VideoMedia = {
  props: BackgroundVideo;
  type: MediaType.Video;
};
