import React from 'react';

import type { Video as Props } from './video.types';

const Video = React.forwardRef<HTMLVideoElement, Props>(
  ({ autoplay, loop, muted, playsInline, poster, source, type }, ref) => (
    <video
      className="video"
      autoPlay={autoplay}
      loop={loop}
      muted={autoplay || muted}
      playsInline={playsInline}
      poster={poster}
      ref={ref}
    >
      <source src={source} type={type} />
    </video>
  ),
);

Video.displayName = 'Video';

export default Video;
